<template>
  <main class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
    <h2
      class="my-10 text-2xl font-semibold text-gray-700 dark:text-gray-200 flex"
    > 
     Colors
    </h2>

    <form @submit.prevent="submit" enctype="multipart/form-data" class="bg-white rounded-lg shadow-md dark:bg-gray-800 pb-12">
      <hr class="rounded-t-lg mb-4 lg:mb-10 border-g dark:border-gray-600" />
      <div    
        class="max-w-2xl mx-auto pt-8 mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
      >
        <div class="sm:col-span-3">
          <label
            for="colorName"
            class="block text-sm font-medium text-gray-700"
          >
            ColorName
          </label>
          <div class="mt-1">
            <input
              type="text"
              v-model="colorInfo.colorName"
              name="colorName"
              id="colorName"
              autocomplete="given-name"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      
        <div class="sm:col-span-3">
          <label
            for="colorId"
            class="block text-sm font-medium text-gray-700"
          >
            Color
          </label>
          <div class="mt-1">
            <div class="h-10 w-10 rounded-full overflow-hidden">
              <input
                type="color"
                v-model="colorInfo.hexadecimal"
                name="colorName"
                id="colorName"
                class="w-full h-full"
              />
            </div>
            
          </div>
        </div>
      </div>



      <div class="mt-8 mb-10 w-full flex items-center justify-center">
        <button
          class="uppercase w-56 text-centeruppercase py-2 text-xs md:text-sm font-medium leading-5 text-white  bg-black border border-transparent rounded-lg transition-colors duration-300"
        
          @click.prevent="submit"
          id="submit"
          type="submit"
        >
        Add
        </button>
      </div>
    </form>
    <div v-if="colors.length != 0" class="w-full whitespace-no-wrap mt-6">
      <div
        class="flex justify-between items-center pt-4 pb-2 text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
      >
        <span class="w-6/12 px-4 py-3">ColorName</span>
        <span class="w-5/12 px-4 py-3">Hexadecimal</span>
        <span class="w-1/12 px-4 py-3"></span>
      </div>
      <div class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
        <div
          :key="index"
          v-for="(color, index) in colors"
          class="flex justify-between items-center  text-gray-700 dark:text-gray-400 "
        >
          <span class="w-6/12 px-4 py-3 text-sm">
            <p class="font-semibold">{{ color.colorName }}</p>
          </span>
          <span class="w-5/12 px-4 py-3 text-sm">
            <p class="font-semibold">{{ color.hexadecimal }}</p>
          </span>     
          <span class="w-1/12 px-4 py-3 flex justify-center ">
            <button
              @click="deleteButton(color.colorId,index)"
            >
              <svg
                class="w-5 h-5"
                height="512pt"
                viewBox="-57 0 512 512"
                width="512pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0"
                />
                <path
                  d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0"
                />
                <path
                  d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0"
                />
              </svg>
            </button>
          </span>


        </div>
      </div>
      <div
        class="flex justify-between items-center px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
      >
        <el-pagination
          background
          :page-size="filter.pageSize"
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="filter.pageNo"
          @current-change="PageChanged"
          class="pagination justify-content-center pagination-sm "
        >
        </el-pagination>
      </div>
    </div>
    <div
      v-else
      id="emptyState"
      class="text-gray_5 text-sm text-center h-96 flex items-center justify-center"
    >
      <div
        v-if="loading"
        id="testtest"
        class="h-64 flex justify-center items-center"
      >
        <svg
          class="w-10 h-w-10 rounded-full"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#fff"
        >
          <g fill="none">
            <g transform="translate(1 1)" stroke-width="2">
              <circle
                stroke="#E1E7EC"
                stroke-opacity=".5"
                cx="18"
                cy="18"
                r="18"
              />
              <path stroke="#AD4BB8" d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
      <div
        v-else
        class="h-64 flex justify-center items-center w-full bg-white  dark:bg-gray-800"
      >
        No Colors yet..
      </div>
    </div>

    <div
      v-if="showAlert"
      class="absolute inset-0 h-full w-full bg-gray-700 bg-opacity-75 flex justify-center items-center"
    >
      <div v-if="loading" class="h-64 flex justify-center items-center">
        <svg class="w-10 h-w-10 rounded-full" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none">
            <g transform="translate(1 1)" stroke-width="2">
              <circle
                stroke="#E1E7EC"
                stroke-opacity=".5"
                cx="18"
                cy="18"
                r="18"
              />
              <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>

      <div v-else class="w-1/3 bg-cool-gray-100 rounded">
        <div
          v-if="Successed"
          class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50"
        >
          <div class="flex justify-end">
            <button @click="showAlert = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="38" stroke="black" stroke-width="4" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.3149 28.7293C57.2486 29.6808 57.225 31.2002 56.2621 32.123L36.2264 51.323C35.2843 52.2257 33.7871 52.2257 32.8451 51.323L23.7379 42.5957C22.775 41.673 22.7514 40.1535 23.6851 39.202C24.6188 38.2504 26.1563 38.227 27.1192 39.1498L34.5357 46.2569L52.8808 28.677C53.8437 27.7543 55.3812 27.7777 56.3149 28.7293Z"
                fill="black"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">{{ addSuccessed }}</p>
            <!-- <p class="text-sm mt-2">Permanently deleted</p> -->
          </div>

          <div class="mt-10 flex justify-center">
            <button
               class="bg-black text-center text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
              @click="showAlert = false" 
              >Back</button>
          </div>
        </div>

        <div
          v-else
          class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50"
        >
          <div class="flex justify-end">
            <button @click="showAlert = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30 58.5C45.7401 58.5 58.5 45.7401 58.5 30C58.5 14.2599 45.7401 1.5 30 1.5C14.2599 1.5 1.5 14.2599 1.5 30C1.5 45.7401 14.2599 58.5 30 58.5Z"
                stroke="black"
                stroke-width="3"
              />
              <path
                d="M38 22L22 38"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 22L38 38"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">{{ addErorr }}</p>
            <!-- <p class="text-sm mt-2">Permanently deleted</p> -->
          </div>

          <div class="mt-10 flex justify-center">
            <button
              @click="showAlert = false"
              class="bg-black text-center text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  created() {},
  mounted() {
    window.scrollTo(top);
    this.GetColors();
  },
  data() {
    return {
      productId: null,
      showAlert: false,
      loading: false,
      Successed: false,
      addErorr: null,
      filter:{
      pageNo: 1,
      pageSize: 5,
      },
      data: [],
      submit_text: "",
      colors: [],
      total: null,
      colorInfo:{
      colorName:"",
      hexadecimal:"",
      }
            
        };
  },
  methods: {
    PageChanged(pageNo) {
      this.pageNo = pageNo;
      this.GetColors();
    },
    GetColors() {
      this.$http.colorsService
        .GetColors(this.filter)
        .then((res) => {
          this.loading = false;
          this.Successed = true;
          this.addSuccessed = res.data.result.message;
          this.data = res.data;
          this.colors = res.data.result.colors;
          this.total = res.data.result.total;
        })
        .catch((err) => {
          this.loading = false;
          this.Successed = false;
          this.addErorr = err.response.data.message;
        });
    },
    submit() {
      this.showAlert = true;
      this.loading = true;
        this.$http.colorsService
          .AddColor(this.colorInfo)
          .then((res) => {
            this.loading = false;
            this.Successed = true;
            this.addSuccessed = res.data.result.message;
            this.colors.unshift({
              colorId:res.data.result.addedColor.colorId,
              colorName: res.data.result.addedColor.colorName,
              hexadecimal: res.data.result.addedColor.hexadecimal,
            });
          })
          .catch((err) => {
            this.loading = false;
            this.Successed = false;
            this.addErorr = err.response.data.message;
          });
      
    },
    deleteButton(colorId, index) {
      this.loading = true;
      this.showAlert = true;
      this.$http.colorsService
        .DeleteColor(colorId)
        .then((res) => {
         this.loading = false;
         this.Successed = true;
          this.addSuccessed = res.data.result.message;
          this.colors.splice(index, 1);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.Successed = false;
          this.addErorr = err.response.data.message;
        });
    },
  },
};
</script>

<style>
.test {
  background-color: #035388;
  color: white;
}
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

.submit button[disabled],
.submit button[disabled]:hover,
.submit button[disabled]:active {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #ccc;
  cursor: not-allowed;
}
</style>
